<template>
    <div>
        <ul class="dom-list" v-if="type == 1">
          <li class="dom" style="position:relative;width: 540px;height: 108px;margin-bottom: 10px;background: #eeeeee;" v-for="(item, i) in list" :key="i">
            <img src="../assets/3.png" style="width: 540px;height: 108px;position: absolute;top: 0;left: 0;"/>
            <div style="display: flex;z-index: 100;position: relative;align-items: center;height: 100%;justify-content: flex-end;    overflow: hidden;
      transform: scale(0.84);">
              
              <div style="height:100%;flex:1;display: flex;    width: 100%;">
                <div style="height:100%; ">
                  <img style="    height: calc(100% - 30px);margin-top: 5px;" :src="item.qrCodeUrl"/>
                <div style="font-size: 12px;">{{item.plantNo}}</div>
                </div>          
                <div style="        padding: 8px 20px;overflow: hidden;">
                  <div style="font-size: 30px;font-weight: 500;text-align: left;white-space: nowrap;">{{item.plantName}}<span style="font-size: 20px;font-weight: normal">{{item.scientificName}}</span></div>
                <div style="display: flex;white-space: nowrap;">
                  <div style="text-align: left;margin-top: 10px;width: 50%; overflow: hidden;">
                    <div v-if="item.alias">别名：{{item.alias}}</div>

                    <div v-if="item.categories">科属：{{item.categories.familyCn + '  ' + item.categories.genusCn}}</div>
                  </div>
                  <div v-if="item.price" style="margin-left: 10px;align-self: flex-end;font-size: 36px;font-weight: 500;display: flex;width: 50%;">
                    <span style="width: calc(60% - 30px);
                    overflow: hidden;
                    display: block;
                    padding: 0;
                    margin: 0;">
                    {{item.price}}
                    </span>
                    <span style="font-size: 28px;vertical-align:bottom;display: contents;">RMB</span></div>
                </div>
  
                </div>
               
              </div>
            </div>
          </li>
        </ul>
  
  
  
        <ul class="dom-list" v-if="type == 2">
          <li class="dom" style="position:relative;width: 400px;height: 120px;margin-bottom: 10px;background: #eeeeee;" v-for="(item, i) in list" :key="i">
            <img src="../assets/2.png" style="width: 400px;height: 120px;position: absolute;top: 0;left: 0;"/>
            <div style="display: flex;z-index: 100;position: relative;align-items: center;height: 100%;justify-content: flex-end">
              
              <div style="height:100%;flex:1;display: flex;    width: 100%;">
                <div style="height:100%;">
                  <img style="    height: calc(100% - 30px);margin-top: 5px;" :src="item.qrCodeUrl"/>
                <div style="font-size: 12px;">{{item.plantNo}}</div>
                </div>          
                <div style="        padding: 8px 20px;overflow: hidden;">
                  <div style="font-size: 30px;font-weight: 500;text-align: left;white-space: nowrap;">{{item.plantName}}<span style="font-size: 20px;font-weight: normal">{{item.scientificName}}</span></div>
                <div style="display: flex;white-space: nowrap;">
                  <div style="text-align: left;margin-top: 10px;width: 50%;
      overflow: hidden;
      ">
                    <div v-if="item.alias">别名：{{item.alias}}</div>
                    <div v-if="item.categories">科属：{{item.categories.familyCn + '  ' + item.categories.genusCn}}</div>
                  </div>
                  <div v-if="item.price" style="margin-left: 10px;align-self: flex-end;font-size: 36px;font-weight: 500;display: flex;width: 50%;">
                    <span style="width: calc(60% - 30px);
                    overflow: hidden;
                    display: block;
                    padding: 0;
                    margin: 0;">
                        {{item.price}}
                    </span>
                    <span style="font-size: 28px;vertical-align:bottom;display: contents;">RMB</span></div>
                </div>
  
                </div>
               
              </div>
            </div>
          </li>
        </ul>
  
  
        <ul class="dom-list" v-if="type == 3">
          <li class="dom" style="position:relative;width: 1100px;height: 125px;margin-bottom: 10px;background: #eeeeee;" v-for="(item, i) in list" :key="i">
            <img src="../assets/1.png" style="width: 1100px;height: 125px;position: absolute;top: 0;left: 0;"/>
            <div style="display: flex;z-index: 100;position: relative;align-items: center;height: 100%;justify-content: flex-end">
              <div style="width:50%;height:100%">
               
              </div>
              <div style="height:100%;flex:1;display: flex;    width: 50%;">
                <div style="height:100%;">
                  <img style="    height: calc(100% - 30px);margin-top: 5px;" :src="item.qrCodeUrl"/>
                <div style="font-size: 12px;">{{item.plantNo}}</div>
                </div>          
                <div style="padding: 8px 20px;overflow: hidden;">
                  <div style="font-size: 30px;font-weight: 500;text-align: left;white-space: nowrap;">{{item.plantName}}<span style="font-size: 20px;font-weight: normal">{{item.scientificName}}</span></div>
                <div style="display: flex;white-space: nowrap;">
                  <div style="text-align: left;margin-top: 10px;width: 50%; overflow: hidden;">
                    <div v-if="item.alias">别名：{{item.alias}}</div>
                    <div v-if="item.categories">科属：{{item.categories.familyCn + '  ' + item.categories.genusCn}}</div>
                  </div>
                  <div v-if="item.price" style="margin-left: 10px;align-self: flex-end;font-size: 36px;font-weight: 500;display: flex;width: 50%;">
                    <span style="width: calc(60% - 30px);
                    overflow: hidden;
                    display: block;
                    padding: 0;
                    margin: 0;">
                        {{item.price}}
                    </span>
                    <span style="font-size: 28px;vertical-align:bottom;display: contents;">RMB</span></div>
                </div>
  
                </div>
               
              </div>
            </div>
          </li>
        </ul>
        <button @click="downloadAll" style="width: 150px; height: 50px;border: 0;background-color: cornflowerblue;color:white;text-align: center;">下载压缩包</button>
        
        <!-- 备案号 -->
        <footer style="text-align: center; margin-top: 20px; padding: 10px; background-color: #f0f0f0;">
            <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style="color: #333; text-decoration: none;">
            沪ICP备12006115号
        </a>
    </footer>
    </div>
</template>
  
<script>
  import html2canvas from "html2canvas";
  import JSZip from "jszip";
  import FileSaver from "file-saver";
  
  export default {
    data() {
      return {
        type: 3,
        failureState: false,
        list: []
      };
    },
    created() {
      let currentURL = window.location.href;
      //let url = new URL(currentURL);
      // /#/type=1&secret=9c724ff06784f619e3d1ab9a405471eb873507f4c85423f1825ddd528ee89607a7b25896d9d0ed65ffff96f50e47dba66221c94e90b91d98c6f3c862f611a7ef456fa0ecf80a389ac511d0ff1b35b55242eaa97bd05a329f5b1541e0f2dba57a60f40a&apikey=219357819969732608
      
        // 提取哈希部分
        let hash = currentURL.split('#/')[1];

        // 使用 URLSearchParams 解析查询参数
        let params = new URLSearchParams(hash);

        // 获取参数值
        let type = params.get('type');
        let secret = params.get('secret');
        let apikey = params.get('apikey');

        console.log('window.location.href:', currentURL);
        console.log('Type:', type);
        console.log('Secret:', secret);
        console.log('API Key:', apikey);
  
      let xmlhttp = new XMLHttpRequest();
      xmlhttp.open('GET', `https://olifeee.cn/api/plant/print/getPlantInfo?type=${type}&secret=${secret}&apikey=${apikey}`);
      xmlhttp.send();
      xmlhttp.onreadystatechange = () => {
        if (xmlhttp.readyState === 4) {
          if (xmlhttp.status === 200 || xmlhttp.status === 304) {
            let res = JSON.parse(xmlhttp.responseText);
            if (res.code === 200) {
              this.list = res.data;
              this.type = type;
            }
            if (res.code === 510) {
              this.failureState = true;
            }
          }
        }
      };
    },
    methods: {
      async downloadAll() {
        const imgs = await this.domsToImgs();
        const zip = new JSZip();
        imgs.forEach((img, i) => {
          const fileName = `图片_${i}.png`;
          const blob = this.base64ToBlob(img);
          zip.file(fileName, blob, { binary: true });
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
          FileSaver.saveAs(content, '导出结果.zip');
        });
      },
      domsToImgs() {
        return new Promise((resolve) => {
          const imgs = [];
          const doms = Array.from(document.querySelectorAll('.dom-list>.dom'));
          doms.forEach((dom) => {
            html2canvas(dom, {
              allowTaint: true,
              useCORS: true,
              backgroundColor: 'white',
              scale: window.devicePixelRatio || 2
            }).then((canvas) => {
              const quality = 1;
              const base64 = canvas.toDataURL('image/png', quality);
              imgs.push(base64);
              if (imgs.length === doms.length) {
                resolve(imgs);
              }
            });
          });
        });
      },
      base64ToBlob(base64) {
        const parts = base64.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  