<template>
  <div id="app">
    <PlantList />
  </div>
</template>

<script>
import PlantList from './components/PlantList.vue';

export default {
  components: {
    PlantList
  }
};
</script>

<style>
/* Add your global styles here */
</style>
