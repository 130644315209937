import PlantList from './components/PlantList.vue';

const routes = [
  {
    path: '/plant-list',
    component: PlantList,
  },
  // 其他路由
];

export default routes;
