import { createApp } from 'vue'; // 使用 Vue 3 的 createApp 方法
import { createRouter, createWebHistory } from 'vue-router'; // Vue Router 4 的 API
import App from './App.vue';
import routes from './routes'; // 假设你有一个 routes.js 文件定义了路由

// 创建路由实例
const router = createRouter({
  history: createWebHistory(), // 使用 HTML5 History 模式
  routes, // 路由定义
});

// 创建 Vue 应用实例并使用路由
const app = createApp(App);
app.use(router);
app.mount('#app');
